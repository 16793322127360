<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-10 is-offset-1">
        <div class="columns is-multiline">
          <div class="column is-6">
            <SdSelect
              rules="required"
              label="School Class"
              v-model="schoolClassId"
              expanded
            >
              <option
                v-for="schoolClass in schoolClasses"
                :key="schoolClass.id"
                :value="schoolClass.id"
              >
                {{ schoolClass.name }}
              </option>
            </SdSelect>
          </div>
          <div class="column is-6">
            <SdSelect rules="required" label="Term" v-model="termId">
              <option v-for="term in terms" :key="term.id" :value="term.id">
                {{ term.title }}
              </option>
            </SdSelect>
          </div>
          <div class="column is-12">
            <div class="buttons is-right">
              <button
                class="button is-success"
                @click="fetchHighestSubjectScores()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-10 is-offset-1">
        <template v-if="loading">
          <loading />
        </template>
        <template v-else>
          <b-table :data="highestSubjectScores" hoverable>
            <b-table-column
              field="#"
              label="#"
              width="40"
              numeric
              v-slot="props"
              >{{ highestSubjectScores.indexOf(props.row) + 1 }}</b-table-column
            >

            <b-table-column label="Subject" v-slot="props">
              {{ props.row.subject_name }}
            </b-table-column>

            <b-table-column label="Reg Id" v-slot="props">{{
              props.row.reg_id
            }}</b-table-column>

            <b-table-column label="Name" v-slot="props">
              {{ props.row.name }}
            </b-table-column>

            <b-table-column label="Highest Score" v-slot="props">{{
              props.row.score
            }}</b-table-column>
          </b-table>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import TERMS from '../../../graphql/term/Terms.gql'
import SdSelect from '../../components/SdSelect'
import { crudNotification } from '../../assets/js/notification'
import { fetchUser } from '@/assets/js/app_info.js'

export default {
  data() {
    return {
      user: {},
      schoolId: null,
      pageTitle: 'Highest Subject Scores',
      term: null,
      termId: null,
      terms: [],
      schoolClass: null,
      schoolClassId: null,
      schoolClasses: [],
      highestSubjectScores: [],
      loading: false,
    }
  },
  apollo: {
    terms: {
      query: TERMS,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
  },
  components: {
    SdSelect,
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Ate Assessment',
        route: `/school/${this.schoolId}/ate_assessment`,
      },
    ])

    fetchUser().then((user) => {
      this.user = user
      this.fetchSchoolClasses()
    })
  },
  methods: {
    fetchHighestSubjectScores() {
      this.loading = true
      this.$apollo
        .query({
          query: gql`
            query HighestSubjectScoresQuery($id: ID!, $termId: ID!) {
              highestSubjectScores(id: $id, termId: $termId) {
                id
                highestSubjectScores
              }
            }
          `,
          variables: {
            id: parseInt(this.schoolClassId),
            termId: parseInt(this.termId),
          },
        })
        .then((response) => {
          this.highestSubjectScores =
            response.data.highestSubjectScores.highestSubjectScores
          this.loading = false
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
}
</script>
